<template>
  <div class="container">
    <section>
      <v-row class="d-flex align-center">
        <v-col cols="12" md="7">
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Sobre"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                O subdistrito de Bento Rodrigues pertence ao distrito de
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_santa_rita' }"
                  >Santa Rita Durão</router-link
                >, que por sua vez faz parte do município de Mariana. Está
                distante cerca de 35km do centro de Mariana e a 124 km de Belo
                Horizonte, capital do Estado de Minas Gerais. Situa-se próximo
                da Serra do Caraça, com acesso pela estrada de terra para a Mina
                Del Rei que ligava Mariana a Santa Rita passando por Camargos,
                em uma região ocupada por fazendas e sítios. Outro caminho
                disponível é pela MG 129, rodovia asfaltada com trânsito
                intenso, principalmente de veículos relacionados à atividade
                mineradora. Com o rompimento da barragem de Fundão, o acesso
                interno ficou impossibilitado pela destruição da comunidade,
                incluindo as vias. Soma-se a isso o controle exercido pela
                Samarco, que fiscaliza a entrada e saída de pessoas em Bento
                Rodrigues, havendo guaritas nas entradas do núcleo urbano. Bento
                teve parte de seu território alagado pelo Dique S4, e
                consequentemente desapareceu o trecho da estrada que conduzia a
                Santa Rita Durão, passando pela comunidade.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="4"
          class="
            d-flex
            flex-column
            justify-center
            align-center
            justify-md-space-between
            align-md-start
          "
        >
          <v-img
            max-width="80%"
            src="../../assets/sobre_territorio_bento_01.png"
          />
        </v-col>
        <v-col cols="12" md="7">
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Processo de formação do povoamento"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                O território de Mariana teve sua ocupação motivada pela extração
                de ouro, quando expedições avançaram por Minas Gerais partindo
                de São Paulo. Parte dos historiadores identificam Miguel Garcia
                e o Coronel Salvador Fernandes Furtado como lideranças das
                caravanas que inicialmente chegaram na região de Mariana. Há
                também indicações de que o paulista Bento Rodrigues foi o
                primeiro a se embrenhar nas terras atravessadas pelo Ribeirão do
                Carmo.<sup>2</sup>
              </p>
              <p>
                Simultaneamente à formação da sede, outros arraiais foram
                surgindo e alguns transformados em freguesia quando Mariana se
                tornou Vila do Carmo, em 1711. A maior parte da ocupação do
                território de Mariana ocorrida no século XVIII se concentrou nas
                margens do Ribeirão do Carmo, chamado assim em homenagem a Nossa
                Senhora do Carmo, bem como ao longo do Rio Piranga, Gualaxo do
                Sul e Gualaxo do Norte.
              </p>
              <p>
                É nas terras percorridas pelo Rio Gualaxo do Norte que surgiram
                as localidades de Antônio Pereira, onde ele nasce, Camargos,
                Gama, Teixeira (atual subdistrito de Paracatu), São José de
                Matias Barbosa (atual município de Barra Longa) e Bento
                Rodrigues. A descoberta de novos pontos para extração de ouro
                foi o que motivou os grupos de garimpeiros a avançar pelo
                território do Rio Gualaxo do Norte, somada à necessidade da
                produção de alimentos para manter tanto as pessoas escravizadas
                que trabalhavam na exploração de ouro, quanto as lideranças dos
                grupos. Desta forma, paralelamente à mineração, eram
                desenvolvidas atividades de plantio e criação de animais, cuja
                produção era voltada para o povoamento onde ocorria a extração e
                para a sede de Mariana.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Entre os cultivos destacavam-se o milho, o feijão e a cana de
                açúcar; a atividade de criação de animais era relativa
                principalmente aos suínos. Havia ainda a fabricação artesanal de
                artigos como a cachaça ou aguardente. Além de produzirem, os
                moradores locais também comercializavam, desenvolvendo assim uma
                capacidade de geração de economia e crescimento de um território
                que por si consumia e fornecia os gêneros alimentícios.
              </p>
              <p>
                Foi no encalço dos exploradores que chegaram a Camargos, que o
                paulista Bento Rodrigues teria encontrado em terras a norte
                daquele povoamento grande quantidade de ouro de uma só vez, por
                volta de 1702 <sup>3</sup>. A localidade foi batizada com o nome
                desse paulista, e a notícia do descobrimento se espalhou,
                atraindo muitas pessoas em busca de ouro. Assim, foi acontecendo
                a ocupação e fixação de moradores no local, que além das
                construções residenciais, recebeu uma capela em louvor a São
                Bento, que, conforme o Cônego Raimundo Trindade <sup>4</sup>, já
                estava construída em 1718.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="
            d-flex
            flex-column
            justify-center
            align-center
            justify-md-end
            align-md-end
          "
        >
          <v-img
            max-width="80%"
            src="../../assets/sobre_territorio_bento_03.png"
          />
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="4"
          class="
            d-flex
            flex-column
            justify-center
            align-center
            justify-md-space-between
            align-md-start
          "
        >
          <v-img
            max-width="80%"
            src="../../assets/sobre_territorio_bento_02.png"
          />
        </v-col>
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Ainda nas primeiras décadas do século XVIII, no antigo Morro do
                Fraga, a norte do núcleo em que se formava Bento Rodrigues, foi
                construída outra capela, dedicada a São Gregório e Nossa Senhora
                do Livramento. A capela foi edificada em um sítio pertencente a
                Gregório Lima, onde eram produzidos alimentos e havia condição
                de se minerar. A antiga capela ficou conhecida pelos moradores
                de Bento nos tempos mais recentes como “igreja queimada”. O
                templo possuía dimensões avantajadas para uma capela particular
                e aperfeiçoamento técnico em sua construção, sendo as paredes
                com pedras sobrepostas e o arco cruzeiro com elementos de
                cantaria, indicando as possibilidades financeiras de quem a
                construiu.
              </p>
              <p>
                Pouco antes de 1750, a região onde se encontrava essa fazenda,
                que era predominantemente caracterizada por mata, passou a ser
                ocupada por outras propriedades rurais, entre elas a do Coronel
                João Gonçalves Fraga, que por sua importância econômica e
                política, deu nome ao lugar.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                O povoado de Bento Rodrigues, existente entre 1720 e 1730,
                caracterizava-se pela presença de fazendas onde eram realizadas
                atividades de produção agrícola e criação de animais,
                conjuntamente com a extração de ouro nos morros e nos cursos
                d’água. Nessa época, já havia também um núcleo conformado pela
                Capela de São Bento, residências e edificações comerciais. A
                localidade era percorrida de norte a sul por um caminho que a
                ligava às freguesias de Camargos e a do Inficcionado (atual
                Santa Rita Durão), fazendo parte da Estrada Real que passou a
                conectar a Vila do Carmo à Vila de Sabará, contornando a Serra
                do Caraça.
              </p>
              <p>
                No que diz respeito à economia da época, certamente foram
                abertos serviços de extração de ouro na região de Bento
                Rodrigues, mas, apesar de significativos, o volume retirado não
                era tão expressivo quanto em outras localidades situadas ao
                longo do Ribeirão do Carmo. Povoados como Camargos e Bento
                Rodrigues estavam longe de desempenharem uma condição volumosa
                na produção aurífera, cada um representava apenas 2% da produção
                de ouro extraída em Mariana entre 1721 e 1733.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="
            d-flex
            flex-column
            justify-center
            align-center
            justify-md-end
            align-md-end
          "
        >
          <v-img
            max-width="80%"
            src="../../assets/sobre_territorio_bento_04.png"
          />
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex align-center flex-row-reverse">
        <v-col cols="12" md="7">
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Bento Rodrigues no século XIX"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                A partir de meados do século XVIII, Minas Gerais, e por
                conseguinte Mariana, se deparou com o declínio da atividade de
                extração de ouro. As consequências deste foram diferentes para
                cada localidade do território marianense, a depender do quanto
                essa atividade era significativa e do quanto haviam sido
                desenvolvidas outras atividades econômicas em paralelo.
              </p>
              <p>
                Neste contexto, tanto nos casos de povoamentos que tiveram sua
                adaptação mais rápida quanto nos que mais lentamente criaram
                outros meios de se manter, as atividades agropecuárias e da
                fabricação artesanal, realizadas desde o século XVIII, passaram
                a ter maior importância na economia mineira ao longo do século
                XIX. O sustento dos povoamentos passou a acontecer a partir da
                produção de grãos, da criação de gado e da fabricação de
                cachaça. Eram fabricados ainda tecidos de panos grossos, peças
                de ferro produzidas nas pequenas forjas das fazendas e alimentos
                como o toucinho defumado, que faziam parte do consumo tanto de
                pessoas livres quanto das escravizadas.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Os recenseamentos produzidos em Teixeira, Camargos e Bento
                Rodrigues entre 1820 e 1830 demonstram esse processo de
                adaptação na região cortada pelo Rio Gualaxo do Norte. Em
                relação a Bento Rodrigues, o censo de 1831 indica que existiam
                91 domicílios e 454 habitantes. Os ofícios levantados entre as
                “ocupações” de Bento Rodrigues foram o de pároco, negociante,
                caixeiro, jornaleiro, costureira, venda, viver de soldo,
                mineiro, tropeiro, fiadeira, faiscador, carpinteiro, latoeiro,
                estalagem e empregados de chácara.
              </p>
              <p>
                Cabe observar que a função de “mineiro” era pouco expressiva
                neste povoamento em 1831, identificada somente em 4 domicílios.
                Outras atividades, no entanto, eram bastante expressivas,
                indicando que a economia de Bento era variada e não dependia
                exclusivamente da extração de ouro. Destaca-se a função de
                fiadeira, que foi levantada em 32 dos 91 domicílios (35%), bem
                como o comércio, presente em 9 domicílios voltados para os
                “negócios”, 4 vendas, 5 tropeiros, 5 caixeiros viajantes e uma
                estalagem.
              </p>
              <p>
                A estalagem pertencia a Antônio Jorge Machado, e os dados do
                censo de 1831 levam a acreditar que nela se situava o curral e
                cocho de pedra que foram atingidos pelo rompimento da Barragem
                de Fundão em novembro de 2015 e que posteriormente foram
                submersos pelo lago do Dique S4. A importância do curral e dos
                cochos de pedra reforça a percepção de que Bento Rodrigues
                tornava-se uma parada quase obrigatória entre os viajantes que
                transitavam entre Ouro Preto e as vilas ao norte pela Estrada
                Real.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex align-center flex-row-reverse">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                O mesmo censo registrou 94 pessoas escravizadas. Os habitantes
                catalogados como “brancos” representavam apenas 6% (29) dos 454
                moradores; os mulatos e negros, assim como em Camargos,
                conformavam a maioria da população (94%). O grupo de
                “africanos/pretos” (15% da população), apesar de ser menor do
                que em Camargos, ainda é bastante representativa se considerada
                a aquisição de novos grupos de pessoas escravizadas nesta fase
                caracterizada pela “crise da mineração”.
              </p>
              <p style="text-align: center">
                <strong> Autora: Ana Paula Alves Ferreira <sup>5</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <sup>1</sup> O Governo de Minas Gerais, em 21 de setembro de 2016,
              por meio do Decreto NE nº 500, autorizou o início das obras de
              construção do dique S4 da Samarco, no subdistrito de Bento
              Rodrigues. O S4 integra o sistema emergencial de retenção de
              sedimentos composto pelos diques S1, S2 e S3, além das barragens
              de Fundão e Nova Santarém. O decreto do Governo Estadual
              estabeleceu a requisição administrativa de 55 propriedades
              particulares atingidas na área de Bento Rodrigues onde foi
              instalado o S4, alagando cerca de 1/3 da comunidade.
            </p>
            <p>
              <sup>2</sup> As informações a respeito do histórico e da
              caracterização de Bento Rodrigues tiveram como base principal o
              Diagnóstico preliminar dos bens culturais identificados no
              território atingido em Mariana pelo rompimento da barragem de
              Fundão, elaborado em 2017 pela empresa Pólen Consultoria
              Patrimônio e Projetos Ltda. O Diagnóstico foi encomendado pela
              Comissão dos Atingidos e pela Cáritas Brasileira MG e, juntamente
              com os dados do cadastro realizado pela Cáritas, possibilitou um
              entendimento mais amplo das relações culturais, sociais e
              econômicas que foram consolidadas nesse território ao longo dos
              séculos. O trabalho abrange aspectos geográficos, históricos,
              arqueológicos, arquitetônicos, antropológicos e museológicos.
              Contudo, ainda se trata de um estudo e diagnóstico preliminar,
              sendo necessário a ampliação do conhecimento sobre as comunidades
              atingidas.
            </p>
            <p>
              <sup>3</sup> Conforme relato de Diogo de Vasconcelos, o paulista
              Bento Rodrigues havia retirado, “em cinco braças de extensão”,
              “cinco arrobas de ouro, caso que deu invasão dos flibusteiros em
              tal desordem, que desse arraial fizeram o mais barulhento lugar da
              antiguidade". Diogo de Vasconcelos (1843-1927) foi advogado,
              deputado, senador e agente executivo de Ouro Preto, além de ser um
              dos fundadores do Instituto Histórico e Geográfico de Minas
              Gerais. Realizou importantes contribuições historiográficas sobre
              Minas Gerais, incluindo o município de Mariana.
            </p>
            <p>
              <sup>4</sup> O Cônego Raimundo Otávio da Trindade nasceu na
              freguesia de Furquim, município de Mariana, no dia 20 de novembro
              de 1883. Destaca-se por suas pesquisas nas antigas bibliotecas,
              nos arquivos de igrejas e museus e, especialmente nos arquivos da
              Cúria Metropolitana de Mariana. Tornou-se um dos maiores
              historiadores de Minas Gerais, escrevendo a história da
              Arquidiocese de Mariana, que se mescla com o próprio nascimento da
              história mineira.
            </p>
            <p>
              <sup>5</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <strong>Referências Bibliográficas</strong>
            </p>
            <p>
              CASTRIOTA, Leonardo Barci. Dossiê de Tombamento de Bento
              Rodrigues. UFMG. Belo Horizonte, 2019. Disponível em:
              https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento.
              Acesso em: 30 mar. 2022.
            </p>
            <p>
              PÓLEN Consultoria Patrimônio e Projetos Ltda. Diagnóstico
              preliminar dos bens culturais identificados no território atingido
              em Mariana pelo rompimento da barragem de Fundão. Mariana, 2017.
            </p>
            <p>
              TEIXEIRA, Vanessa Cerqueira. Fé e cultura barroca sob o manto
              mercedário: devoção, religiosidade e vivências a partir da
              irmandade de Nossa Senhora das Mercês de Mariana (1749-1822). 220
              f. 2017. Dissertação (Mestrado em História) — Instituto de
              Ciências Humanas, Universidade Federal de Juiz de Fora Juiz de
              Fora-MG, 2017.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
section {
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
